import React, { useEffect } from 'react';
import {
  Card,
  Container,
  Divider,
  Heading,
  Image,
  List,
  Loader,
  Text,
  useLoader,
} from '@legalshield/adonis-ux-framework';

import RealmUtility from '../../utils/realm';
import { formatDate2Digit } from '../../helpers/stringFormatter';
import { getPreferred, sortByPropAndPreferred } from '../../utils/utils';
import { useGetProfile } from '../../hooks/useProfile';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import shieldIcon from '../../images/Safeguard.svg';
import { NewEditContainer } from '../NewProfile/NewEditContainer/NewEditContainer';
import {
  AddressPresenter,
  DateOfBirthPresenter,
  NamePresenter,
  EmailPresenter,
  PhoneNumberPresenter,
  PreferredNamePresenter,
} from './Presenters/';
import { Layout } from '../Layout/Layout';

const NewProfile: React.FC = () => {
  const { data: profileData, isLoading, isSuccess } = useGetProfile();
  const loader = useLoader();
  const isMobileDevice = window.innerWidth <= 576;

  useEffect(() => {
    if (isLoading) {
      loader.Loading();
    } else if (isSuccess) {
      loader.Blank();
    }
  }, [isLoading, isSuccess]);

  const nameData =
    isSuccess && profileData
      ? new NamePresenter(profileData.firstName, profileData.middleName, profileData.lastName, profileData.version)
      : {};
  const preferredNameData =
    isSuccess && profileData ? new PreferredNamePresenter(profileData.preferredName, profileData.version) : {};
  const dateOfBirthData =
    isSuccess && profileData
      ? new DateOfBirthPresenter(
          profileData.dateOfBirth,
          formatDate2Digit(profileData.dateOfBirth || ''),
          profileData.version,
        )
      : {};
  const sortedEmailData = isSuccess && profileData ? sortByPropAndPreferred(profileData.emails, 'address', true) : [];
  const emailData = isSuccess && profileData ? new EmailPresenter(sortedEmailData, profileData.version) : {};
  const sortedPhoneNumberData =
    isSuccess && profileData ? sortByPropAndPreferred(profileData.phoneNumbers, 'number', true) : [];
  const phoneNumberData =
    isSuccess && profileData ? new PhoneNumberPresenter(sortedPhoneNumberData, profileData.version) : {};
  const preferredAddress = isSuccess && profileData ? getPreferred(profileData.addresses) : null;
  const fullAddressString = preferredAddress
    ? `${preferredAddress.address1}, ${preferredAddress.address2 || ''} ${preferredAddress.locality}, ` +
      `${preferredAddress.administrativeArea} ${preferredAddress.postalCode} ${preferredAddress.country}`
    : '';
  const addressData =
    isSuccess && profileData ? new AddressPresenter(preferredAddress, fullAddressString, profileData.version) : {};

  const crumbs = [
    { label: string_table.OVERVIEW_BREADCRUMB, link: '/overview' },
    { label: string_table.PROFILE_TITLE, link: '/overview/profile' },
  ];

  return (
    <Layout crumbs={crumbs} title={string_table.NEW_PROFILE_TITLE} subtitle={string_table.NEW_PROFILE_DESCRIPTION}>
      {isLoading ? (
        <Loader loaderState={loader.loaderState} />
      ) : (
        <>
          <Divider classNames={['py-4']} />
          <Container style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Container style={{ maxWidth: 424 }}>
              <List>
                <NewEditContainer data-testid="mainNameContainer" fieldName={'name'} data={nameData} />
                <NewEditContainer
                  data-testid="mainPreferredNameContainer"
                  fieldName={'preferredName'}
                  data={preferredNameData}
                />
                {!RealmUtility.isGroupAdmin() && (
                  <NewEditContainer
                    data-testid="mainDateOfBirthContainer"
                    fieldName={'dateOfBirth'}
                    data={dateOfBirthData}
                  />
                )}
                <NewEditContainer data-testid="mainEmailContainer" fieldName={'email'} data={emailData} />
                <NewEditContainer
                  data-testid="mainPhoneNumberContainer"
                  fieldName={'phoneNumber'}
                  data={phoneNumberData}
                />
                {!RealmUtility.isGroupAdmin() && (
                  <NewEditContainer data-testid="mainAddressContainer" fieldName={'address'} data={addressData} />
                )}
              </List>
            </Container>
            {!isMobileDevice && (
              <Container data-testid="infoBoxContainer">
                <Container classNames={['m-5']} style={{ maxWidth: 312 }}>
                  <Card>
                    <Card.Content>
                      <Container>
                        <Image src={shieldIcon} />
                        <Container classNames={['py-4']}>
                          <Heading as="T16" text={string_table.INFO_BOX_TITLE_PROFILE} classNames={['pb-3']} />
                        </Container>
                        <Text text={string_table.INFO_BOX_MESSAGE_PROFILE} />
                      </Container>
                    </Card.Content>
                  </Card>
                </Container>
              </Container>
            )}
          </Container>
        </>
      )}
    </Layout>
  );
};

export default NewProfile;
