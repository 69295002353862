import React, { FC, Fragment } from 'react';
import { Container, Title } from '@legalshield/adonis-ux-framework';
import { Realm } from '@legalshield/frontend-commons/dist/sdk/identities';

import CardGrid, { renderGridCardButtons } from '../Card/CardGrid';
import RealmUtility from '../../utils/realm';
import { defaultNavButtons, userNavButtons } from '../NavigationBar/NavigationBar';

export const ManageAccount: FC = () => {
  const realm = RealmUtility.getRealm();
  const cards = realm === Realm.User ? userNavButtons : defaultNavButtons;

  return (
    <Fragment>
      <Container flexbox alignItems="flex-start" justifyContent="flex-start" classNames={['mt-6']}>
        <Title text={string_table.MANAGE_YOUR_ACCOUNT} textSize="large" />
      </Container>
      <CardGrid>{renderGridCardButtons(cards)}</CardGrid>
    </Fragment>
  );
};
