import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Container, GridCol } from '@legalshield/adonis-ux-framework';

import CardGrid from '../Card/CardGrid';

export const SkeletonCard: FC = () => (
  <CardGrid classNames={['skeleton__container']}>
    <GridCol>
      <div className="lsux-card accounts-card p-5">
        <Container flexbox flexDirection="column">
          <Skeleton circle width={40} height={40} />
          <Skeleton count={3} className="mt-5" />
        </Container>
      </div>
    </GridCol>
    <GridCol>
      <div className="lsux-card accounts-card p-5">
        <Container flexbox flexDirection="column">
          <Skeleton circle width={40} height={40} />
          <Skeleton count={3} className="mt-5" />
        </Container>
      </div>
    </GridCol>
  </CardGrid>
);
